import { Modal } from 'antd';
import { useState } from 'react';
import EditorForm from 'components/EditorForm';
import { toPrimitive } from 'utils/changer';
import { updateSch, createSch } from 'store';
import { useDispatch } from 'react-redux'

const EditorModal = ({ user, openEditor, setOpenEditor, title, schData, setSchData }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const handleOk = (values) => {
    const objectId = schData?.objectId ? schData?.objectId : false;
    const reminder = !!values.ReminderDate ? true : false;
    const reqData = toPrimitive(values, objectId, reminder);

    (objectId && objectId !== "") ? dispatch(updateSch(reqData, objectId)) : dispatch(createSch(reqData));
    setSchData({})
    setLoading(true);
    setTimeout(() => {
      setOpenEditor(false);
      setLoading(false);
    }, 500);
  };

  const handleCancel = () => {
    setSchData({})
    setOpenEditor(false);
  };

  return (
    <>
      <Modal
        title={title}
        open={openEditor}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <EditorForm user={user} handleOk={handleOk} loading={loading} handleCancel={handleCancel} schData={schData} />
      </Modal>
    </>
  );
};

export default EditorModal;