import { Button, message, Popconfirm } from 'antd';

const ConfirmBtn = ({ tableButtonSize, onDelete, selectedById, DeleteOutlined }) => {
  const confirm = (e) => {
    console.log(e);
    message.success('Click on Yes');
    onDelete(selectedById);
  };
  const cancel = (e) => {
    console.log(e);
    message.error('Click on No');
  };
  return (
    <Popconfirm
      title="Delete Scholarship"
      description="Are you sure to delete this data?"
      onConfirm={confirm}
      onCancel={cancel}
      okText="Delete"
      cancelText="Cancel"
    >
      <Button
        size={tableButtonSize}
        key='onDelete'
        title='Delete Scholarship'
        type='default'>
        <DeleteOutlined className='table-button-font-size' />
      </Button>
    </Popconfirm>
  );
}
export default ConfirmBtn;