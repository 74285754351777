export const searchFilter = (data, searchText, moment) => {
  let tmp;
  if (data === undefined) return;
  const filtered = data.filter((item) => {
    let flag = false;
    // console.log("item", item)
    for (const key in data[0]) {
      if (
        key === 'ScholarshipName' ||
        key === 'RewardAmmount' ||
        key === 'Deadline' ||
        key === 'Status'
      ) {
        if (key === 'RewardAmmount') tmp = item[key].toString();
        else if (key === 'Deadline') {
          tmp = moment(item[key]).format('YYYY-MM-DD');
        } else {
          tmp = item[key];
        }
        // console.log(tmp)
        flag ||= tmp.toLowerCase().includes(searchText.toLowerCase());
      }
    }
    return flag;
  });
  return filtered;
};
