import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import AuthLeftSideBar from './AuthLeftSideBar';
import ForgotPasswordRightSideBar from './ForgotPasswordRightSideBar';
import { authLeftColSpan, authRightColSpan } from 'data/authConstants';
import { forgotPassword } from 'store';

export default function ForgotPassword() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const [isBtnDisabled, setBtnDisabled]=useState(false);
  const onSubmit = async ({email}) => {
    setBtnDisabled(true);
    console.log('forgot password => ', email)
    await dispatch(forgotPassword(email));
    setBtnDisabled(false);
  };

  return (
    <div className="form-login-page">
      <Row>
        <Col className='form-login-left' span={authLeftColSpan}>
          <AuthLeftSideBar />
        </Col>
        <Col className='form-login-right' span={authRightColSpan}>
          <ForgotPasswordRightSideBar
            isAuthenticated={isAuthenticated}
            onSubmit={onSubmit}
            isBtnDisabled={isBtnDisabled}
          />
        </Col>
      </Row>
    </div>
  )
}
