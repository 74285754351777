import { Button } from "antd";
import { LinkOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { tableButtonSize } from "./tableConstants";
import ConfirmBtn from "components/ConfirmBtn";
export const columns = (onLink, onEdit, onDelete) => [
  {
    title: "SCHOLARSHIP NAME",
    dataIndex: "ScholarshipName",
    key: "scholarshipName",
    sorter: (a, b) => a.ScholarshipName.length - b.ScholarshipName.length,
  },
  {
    title: "Student",
    dataIndex: "Student",
    key: "student",
    render: (text) => <div style={{ textAlign: "right" }}>{text}</div>,
    sorter: (a, b) => 0,
  },
  {
    title: "AMOUNT",
    dataIndex: "RewardAmmount",
    key: "rewardAmmount",
    render: (text) => (
      <div style={{ textAlign: "right" }}>
        {text.toLocaleString("en-us", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
        })}
      </div>
    ),
    maxWidth: 120,
    sorter: (a, b) => a.RewardAmmount - b.RewardAmmount,
  },
  {
    title: "DEADLINE",
    dataIndex: "Deadline",
    key: "deadline",
    render: (deadline) => (
      <div style={{ textAlign: "right" }}>
        {moment(deadline).format("YYYY-MM-DD")}
      </div>
    ),
    maxWidth: 100,
    sorter: (a, b) => a.Deadline - b.Deadline,
  },
  {
    title: "STATUS",
    dataIndex: "Status",
    key: "status",
    render: (text) => <div style={{ textAlign: "right" }}>{text}</div>,
    maxWidth: 160,
    sorter: (a, b) => a.Status.length - b.Status.length,
  },
  {
    title: "ACTIONS",
    dataIndex: "objectId",
    key: "actions",
    align: "center",
    maxWidth: 160,
    render: (_, record) => (
      <>
        <Button.Group>
          {record.ScholarshipURL && (
            <Button
              size={tableButtonSize}
              title="Open Scholarship (in new window)"
              key="onLink"
              onClick={() => onLink(record.ScholarshipURL)}
              type="default"
            >
              <LinkOutlined className="table-button-font-size" />
            </Button>
          )}

          {record.ScholarshipApplicationURL && (
            <Button
              size={tableButtonSize}
              title="Open Scholarship Application (in new window)"
              key="onLink"
              onClick={() => onLink(record.ScholarshipApplicationURL)}
              type="default"
            >
              <LinkOutlined className="table-button-font-size" />
            </Button>
          )}
          <Button
            size={tableButtonSize}
            key="onEdit"
            title="Edit Scholarship"
            onClick={() => onEdit(record)}
            type="default"
          >
            <EditOutlined className="table-button-font-size" />
          </Button>
          <ConfirmBtn
            tableButtonSize={tableButtonSize}
            onDelete={onDelete}
            selectedById={record.objectId}
            DeleteOutlined={DeleteOutlined}
          />
        </Button.Group>
      </>
    ),
  },
];
